<template>
  <section v-if="!connectorModelId || items">
    <PanelHeaderEquipmentList
      v-if="header"
      :title="title"
      :nav="nav"
      :connector_id="connectorId"
      icon="fa fa-dashboard"
    />
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby=""
      v-if="isInstanceListEmpty && showEmptyPanel"
    >
      <EmptyListAlert
        buttonLabel="add_new_connector"
        importLabel="import_connectors"
        @import="importConnectors()"
        :newItemPath="newItemPath"
      />
    </div>
    <template v-else>
      <div class="tab-pane active" id="edit" role="tabpanel" aria-labelledby="">
        <SearchableTable
          class="searchable-table"
          :items="filteredItems"
          :fields="fields"
          :commands="commands"
          :multiColumnOrder="false"
          :clientSort="true"
          :pagination="pagination"
          :maxResult="nItems"
          :deepSearch="false"
          :loading="busy"
          :multiSelection="multiSelection"
          :disabled="cloneEnabled"
          @select="onSelect"
          @command="onCommand"
          @loadNextPage="onLoadNextPage"
          @multiColumnSort="onMultiColumnSort"
        >
          <template
            v-slot:customFilters
            v-if="
              contract && contract.allowed_model_connectors && !connectorModelId
            "
          >
            <div class="row">
              <div class="form-group col-md-6 custom-filters">
                <label class="filter-option noselect">
                  <input
                    type="checkbox"
                    v-model="itemFilter.simple_connectors"
                  />
                  <span>{{ $t("connectors") }}</span>
                </label>
                <label class="filter-option noselect">
                  <input type="checkbox" v-model="itemFilter.models" />
                  <span>{{ $t("models") }}</span>
                </label>
                <label class="filter-option noselect">
                  <input
                    type="checkbox"
                    v-model="itemFilter.model_based_connectors"
                  />
                  <span>{{ $t("model_based_connectors") }}</span>
                </label>
              </div>
            </div>
          </template>
          <template #extraButtons>
            <button
              id="export"
              class="btn btn-default"
              :disabled="filteredItems && filteredItems.length == 0"
              @click="exportData"
              :title="$t('export')"
            >
              <i class="fa fa-file-excel-o" />
            </button>
            <button
              v-if="multiSelection.key"
              id="mass_remove"
              class="btn btn-default"
              :disabled="!nSelected"
              @click="massRemove"
              :title="$t('mass_remove')"
            >
              <i class="fa fa-trash-o" />
            </button>
          </template>
          <template #statistics="{ itemsInPage }">
            <ResourceStatistics
              resource="connector"
              :total="nItems"
              :showing="itemsInPage"
              icon="fa fa-plug"
            />
          </template>
          <template v-slot:screen_id="entry">
            <ScreenSelectionCell :item="entry.item">
              <template #before>
                <i class="fa fa-alarm"></i>
              </template>
            </ScreenSelectionCell>
          </template>
          <template v-slot:toggle="entry">
            <div v-if="entry.item.base_model">-</div>
            <ResourceToggle :item="entry.item" v-else />
          </template>
        </SearchableTable>
      </div>
      <div class="tab-pane" id="list" role="tabpanel" aria-labelledby=""></div>
    </template>
    <ModalImportFile
      :entity="importEntity"
      @close="clearImportEntity"
      @resource_imported="resourceImported"
    />
  </section>
</template>
<script>
import DashboardEditPickerBase from "@/components/registration/dashboard-edit-picker-base.vue";
import SearchableTable from "@/components/searchable-table.vue";
import ResourceStatistics from "@/components/statistics/resource-statistics.vue";
import ConnectorService from "@/services/connector.js";
import EmptyListAlert from "@/components/registration/empty-list-alert.vue";
import ModalImportFile from "@/components/modal-import-file.vue";
import ScreenSelectionCell from "@/components/registration/screen-selection-cell.vue";
import ResourceToggle from "@/components/registration/resource-toggle.vue";

export default {
  name: "DashboardEditConnectorPicker",
  extends: DashboardEditPickerBase,
  components: {
    SearchableTable,
    ResourceStatistics,
    EmptyListAlert,
    ModalImportFile,
    ScreenSelectionCell,
    ResourceToggle
  },
  props: {
    showEmptyPanel: {
      type: Boolean,
      required: false,
      default: false
    },
    connectorModelId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      entity: "connector",
      itemFilter: {
        models: false,
        model_based_connectors: false,
        simple_connectors: false
      },
      importEntity: ""
    };
  },
  computed: {
    fields() {
      return [
        {
          name: "id",
          title: "id",
          visible: false
        },
        {
          name: "name",
          title: "connector"
        },
        {
          name: "type",
          title: "type",
          parser: (item) => {
            return this.$tc(
              item.base_model
                ? "model"
                : item.base_model_id
                ? "instance"
                : "connector",
              1
            );
          }
        },
        {
          name: "location_name",
          title: "location",
          order_id: "location",
          parser(item) {
            return item.location_name || "-";
          },
          style() {
            return { "text-align": "center" };
          }
        },
        {
          name: "serial_number",
          title: "serial_number",
          parser(item) {
            return item.serial_number || "-";
          },
          style() {
            return { "text-align": "center" };
          }
        },
        {
          name: "screen_id",
          title: "screen",
          parser: (device) => {
            let screenId = device?.screen_id || "";
            if (!screenId && device?.connector?.screen_id) {
              screenId = device.connector.screen_id;
            }
            return (screenId && this.getScreenById(screenId)?.name) || "";
          }
        },
        {
          name: "status",
          title: "status",
          parser: (item) => {
            return this.$tc(item.is_connected ? "connected" : "disconnected");
          },
          order_id: "connector_status_name"
        },
        {
          name: "toggle",
          title: "enabled",
          parser: (item) => {
            return item.enabled ? 0 : 1;
          },
          style() {
            return { "text-align": "center" };
          }
        }
      ];
    },
    commands() {
      let items = [
        {
          name: "create",
          title: "new",
          icon: "fa fa-plus",
          enabled: () => {
            return this.$can("manage", "EquipamentoCadastro");
          },
          commands: []
        },
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "clone",
          title: "clone",
          icon: "fa fa-copy",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash",
          enabled: () => {
            return (
              !this.nSelected && this.$can("manage", "EquipamentoCadastro")
            );
          }
        },
        { name: "-" },
        {
          name: "open",
          title: "device_list",
          icon: "portal-icon device[solid]",
          enabled: () => {
            return !this.nSelected;
          }
        },
        {
          name: "addChild",
          title: "add_new_device",
          icon: "fa fa-plus",
          enabled: (item) => {
            return (
              !this.nSelected &&
              !item.base_model_id &&
              this.$can("manage", "EstacaoCadastro")
            );
          }
        }
      ];
      if (this.$can("manage", "EstacaoCadastro"))
        items[0].commands.push({
          name: "newDevice",
          title: "new_device",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "DadoCadastro"))
        items[0].commands.push({
          name: "newData",
          title: "new_data",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "AlarmeCadastro"))
        items[0].commands.push({
          name: "newAlarm",
          title: "new_alarm",
          icon: "fa fa-plus"
        });
      if (this.$can("manage", "EquipamentoCadastro"))
        items[0].commands.push({
          name: "importConnectors",
          title: "import_connectors",
          icon: "fa fa-plus"
        });
      return items;
    },
    filterOn() {
      return (
        this?.itemFilter?.models ||
        this?.itemFilter?.model_based_connectors ||
        this?.itemFilter?.simple_connectors
      );
    },
    filteredItems() {
      if (!this.filterOn) {
        return this.list;
      }
      return (this.list || []).filter((i) => {
        return (
          (this.itemFilter.models && i.base_model) ||
          (this.itemFilter.model_based_connectors && i.base_model_id) ||
          (this.itemFilter.simple_connectors &&
            !i.base_model &&
            !i.base_model_id)
        );
      });
    },
    nItems() {
      return this?.connectorModelId ? this.list.length : this.maxResult;
    },
    list() {
      return this?.connectorModelId
        ? (this.items || []).filter(
            ({ base_model_id }) => base_model_id == this?.connectorModelId
          )
        : this.items;
    },
    storeConnectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    newItemPath() {
      let url = `/dashboard/edit/connector/0`;
      if (this?.connectorModelId) {
        url += `?model=${this?.connectorModelId}`;
      }
      return url;
    },
    isInstanceListEmpty() {
      // it does not return true by default. It will returns true after fetch items without succeed instead
      return this?.connectorModelId && !this?.list?.length;
    }
  },
  methods: {
    removalMessage(item) {
      let msg = "";

      // In mass remove mode
      if (item && Array.isArray(item)) {
        return this.warningContent(
          "connector",
          this.$tc("n_records_selected", item.length, { amount: item.length }),
          "you_wont_be_able_to_revert_this"
        );
      }

      // Single record remove mode
      if (item?.device?.connector?.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("data");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      }
      return msg;
    },
    create(command) {
      this.$router.push(this.newItemPath);
    },
    open(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "/device"
      );
    },
    edit(command) {
      this.$router.push(`/dashboard/edit/connector/${command.target.id}#edit`);
    },
    clone(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "?a=c"
      );
    },
    resourceImported(response, entity) {
      let msg = this.$t("success_import_message");
      this.validateSaveResponse(response, msg);
      this.showAlert();

      if (response && response instanceof Array) {
        this.$emit("resource_imported", entity);
        this.fetchItems();
      }
    },
    exportData() {
      let search_input = document.querySelector(
        '.search-column input[type="text"]'
      );
      let params =
        `contract_id=${this.contract.id}&` +
        `base_model_id=${this.connectorModelId}&` +
        `is_base_model=${this?.itemFilter?.models}&` +
        `is_not_base_model=${this?.itemFilter?.simple_connectors}&` +
        `is_instance=${this?.itemFilter?.model_based_connectors}&` +
        `search=${search_input.value}`;
      this.service.export(params, `connectors.csv`);
    },
    remove(command) {
      let config = {
        item: command.target,
        type: "connector",
        resource_key: "connector_id",
        rule: "EquipamentoEscrita",
        service: this.service
      };
      this.validateAndRemove(config, (ret) => {
        if (ret) {
          this.$store.dispatch("dashboard/removeConnector", [
            command.target.id
          ]);
        }
      });
    },
    massRemove(command) {
      let self = this;
      if (this.multiSelection.values.length == 0) {
        return;
      }
      let config = {
        items: this.multiSelection.values,
        type: "connector",
        resource_key: "connector_id",
        rule: "EquipamentoEscrita",
        service: this.service
      };
      let callback = () => {
        self.multiSelection.values = [];
      };
      this.validateAndMassRemove(config, callback);
    },
    addChild(command) {
      this.$router.push(
        "/dashboard/edit/connector/" + command.target.id + "/device/0"
      );
    },
    fetchItems(URL) {
      if (this?.connectorModelId && this.storeConnectorList.length) {
        this.$set(this, "items", this.storeConnectorList);
        this.maxResult = this.items.length;
        return;
      }
      let url = URL || "";
      let query = null;
      if (!url) {
        query = {};
        query.contract_id = this.contract.id;
      }
      this.fetch(query, url).then((ret) => {
        if (ret) {
          this.$store.dispatch("dashboard/setConnectorValue", this.items);
        }
      });
    }
  },
  beforeCreate() {
    this.service = new ConnectorService();
    this.$emit("rule", "EquipamentoAcesso");
  },
  created() {
    this.title = "connector_list";
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 5px 0 30px 0;
  padding: 10px 15px 50px 15px;
  overflow-x: auto;
}
.custom-filters {
  min-width: 100%;
  width: 100%;
  margin: 15px 0px -15px;
  white-space: nowrap;
}
.filter-option {
  margin-right: 15px;
}
.filter-option span {
  vertical-align: top;
  margin-left: 5px;
}
.filter-option:hover {
  cursor: pointer;
  color: #72afd2;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.searchable-table {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .custom-filters {
    white-space: initial;
  }
  #export {
    margin-left: -32px;
  }
}
</style>
