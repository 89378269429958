<template>
  <div style="display: inline">
    <div class="modal fade in" ref="iframe_dialog">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" v-if="dialog.title">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ dialog.title }}</h4>
          </div>
          <div style="position: relative; z-index: 1" v-else>
            <div
              style="
                position: absolute;
                right: 0px;
                top: 0px; /* font-size: 18pt; */
              "
            >
              <span
                class="btn"
                data-dismiss="modal"
                aria-label="Close"
                style="padding: 0px 4px; font-size: 15px; line-height: 1.5"
              >
                <i class="fa fa-close"></i>
              </span>
            </div>
          </div>
          <div class="modal-body">
            <iframe
              ref="iframe"
              v-if="dialog.src"
              class="scrollable-iframe fade in"
              v-bind:src="dialog.src"
              v-on:load.stop.prevent="onModalIframeLoaded"
            />
          </div>
        </div>
      </div>
    </div>
    <a href="" ref="newtab_link" style="display: none" target="_blank" />
    <FloatPanel
      class="float-panel"
      v-for="(popup, ix) in popup_list"
      :key="ix"
      :draggable="true"
      :handleSelf="true"
      :defaultPosition="{ top: popup.position.top, left: popup.position.left }"
      :open="popup.src ? true : false"
      @update:open="popup.src = $event ? popup.src : ''"
      @dragstart="popup.dragging = true"
      @dragend="onPopupDragged(popup, $event)"
    >
      <div
        :style="{
          width: popup.position.width,
          height: popup.position.height,
          position: 'relative'
        }"
      >
        <div class="popup-title" v-if="popup.title">{{ popup.title }}</div>
        <iframe
          v-if="popup.src && !popup.dragging"
          class="scrollable-iframe fade in"
          :id="popup.id"
          :name="popup.id"
          :src="popup.src"
          @load.stop.prevent="onPopUpIframeLoaded(popup, $event)"
        />
      </div>
    </FloatPanel>
  </div>
</template>

<script>
import FloatPanel from "@/components/editor/float-panel.vue";

export default {
  name: "CustomActionManager",
  components: {
    FloatPanel
  },
  props: {
    connector: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      widget_data: null,
      selectedAction: null,
      dialog: {
        title: "",
        src: "",
        ready: false
      },
      popup_list: [],
      retries: 0,
      nack_stack: {}
    };
  },
  computed: {
    deepConnector() {
      if (!this.dialog || !this.dialog.ready) return null;
      let connector = JSON.parse(JSON.stringify(this.connector)) || {};
      connector.dataList = JSON.parse(
        JSON.stringify(
          this.$store.getters["dashboard/dataListFromEquipment"] || []
        )
      );
      return connector;
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    }
  },
  watch: {
    deepConnector: {
      handler(n) {
        if (n && this.dialog && this.dialog.ready) {
          this.notifyData();
        }
      },
      deep: true
    },
    screens: {
      handler(n) {
        if (n && n.length && this.retries) {
          this.retry();
        }
      },
      deep: true
    }
  },
  methods: {
    retry() {
      if (this.retries > 0) {
        this.retries--;
        if (this.selectedAction) {
          // console.log("triggering last action");
          this.trigger(this.selectedAction);
        }
      }
    },
    notify(msg, target) {
      const $target = target || this.$refs.iframe || null;
      if ($target) {
        $target.contentWindow.postMessage({ hi_msg: msg });
      }
    },
    notifyData(target) {
      let action =
        this.selectedAction.action.action || this.selectedAction.action;
      if (target?.id) {
        if (this.nack_stack[target.id]) {
          action = this.nack_stack[target.id];
        } else {
          this.nack_stack[target.id] = JSON.parse(JSON.stringify(action));
        }
      }
      if (action) {
        this.notify({ connector: this.deepConnector, action: action }, target);
      }
    },
    onModalIframeLoaded() {
      if (!this.dialog.ready && this.dialog.src) {
        this.dialog.ready = true;
        this.$nextTick(() => {
          this.notify("render");
          setTimeout(() => {
            this.notifyData();
          }, 1000);
        });
      }
    },
    onPopUpIframeLoaded(popup, $event) {
      if (!popup.ready && popup.src) {
        popup.ready = true;
        let target = $event.target;
        this.$nextTick(() => {
          this.notify("render", target);
          setTimeout(() => {
            this.notifyData(target);
          }, 1000);
        });
      }
    },
    saveData(lastData, info) {
      let payload = {
        formName: this.$utils.uuid(),
        lastData: lastData,
        iValue: info.value,
        dataValueCurrentIndex: parseInt(info.dataIndex ?? -1)
      };
      this.$root.$emit("form:submit", [payload]);
    },
    trigger_panel() {
      let panelName =
        this.selectedAction?.action?.options?.panel_name?.value || "";
      if (!panelName) return;
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "expand":
          this.$store.dispatch("dashboard/fullscreen", "");
          if (this.$store.getters["dashboard/expandedPanel"]) {
            this.$store.dispatch("dashboard/expand", ""); //just collapse thre previous one
            return;
          }
          this.$store.dispatch("dashboard/expand", panelName);
          break;
        case "full_screen":
          // eslint-disable-next-line no-case-declarations
          let el = document.querySelector(`.__${panelName}__`);
          if (el) {
            this.$utils.fullscreen(el, (fullscreen) => {
              this.$store.dispatch("dashboard/expand", "");
              if (fullscreen) {
                this.$store.dispatch("dashboard/fullscreen", panelName);
              } else {
                this.$store.dispatch("dashboard/fullscreen", "");
              }
            });
          }
          break;
        case "modal":
          break;
      }
    },
    trigger_page() {
      /*
        action.options:{
          src:"url",
          params:{
            "key1":"value1",
            "key2":"value2",
            ...
          }
        }
        */
      let src =
        this.selectedAction?.action?.options?.src?.value ||
        this.selectedAction?.action?.options?.url?.value ||
        "";
      if (!src) return;
      let params = null;
      let auth = this?.selectedAction?.action?.options?.auth?.value || false;
      if (auth) {
        params = {
          connector_id: this.connector?.id || "",
          api: this.$http.options.root,
          access_token: this.$cookies.get("access_token"),
          app_static_dir: encodeURIComponent(this.$http.options.dashboard),
          ...(this?.selectedAction?.action?.options?.params || {})
        };
      } else {
        params = {
          ...(this?.selectedAction?.action?.options?.params || {})
        };
      }
      let url = "";
      for (let param in params) {
        url +=
          (url ? "&" : src + "?") +
          param +
          "=" +
          encodeURIComponent(params[param]);
      }
      if (src && !url) {
        url = src;
      }
      let title = (params && params?.title) || "";
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "modal":
          this.dialog.title = title;
          this.dialog.src = url;
          this.dialog.ready = false;
          $(this.$refs.iframe_dialog).modal("show");
          break;
        case "open":
          if (url.startsWith("/")) {
            if (this.$router.path != url) {
              this.$router.push(url);
            }
          } else {
            this.$refs.newtab_link.target = "";
            this.$refs.newtab_link.href = url;
            this.$refs.newtab_link.click();
          }
          break;
        case "home":
          this.$router.push(url);
          break;
        case "previous":
        case "next":
          if (url in this.$router) this.$router[url]();
          break;
        case "tab":
          this.$refs.newtab_link.target = "_blank";
          this.$refs.newtab_link.href = url;
          this.$refs.newtab_link.click();
          break;
        case "window":
          {
            let params = {
              width: window.screen.width,
              height: window.screen.height,
              top: 0,
              left: 0,
              ...(this?.selectedAction?.action?.options?.params || {})
            };
            let sParams = "";
            for (let param in params) {
              sParams +=
                (sParams ? "," : "") +
                param +
                "=" +
                encodeURIComponent(params[param]);
            }
            window.open(url, document.title, sParams);
          }
          break;
      }
    },
    async trigger_screen() {
      let screenId =
        this.selectedAction?.action?.options?.screen_id?.value || "";
      if (!screenId) return;
      let screen = (this.$store.getters["dashboard/screens"] || []).find(
        (i) => i.id == screenId
      );
      let template = undefined;
      let actionParams = this?.selectedAction?.action?.options?.params || {};
      let connector_id =
        actionParams["connector_id"] || this.connector?.id || "screen";
      let url = `/dashboard/equipment/${connector_id}/${screenId}`;
      localStorage.removeItem("_cdim"); //control data id map (transition control variables)
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "open":
          template = this.$store.getters["dashboard/template"](screenId);
          if (!template) {
            if (!screen || !screen?.path) {
              this.retries = 1;
              this.$store.dispatch("dashboard/fetchScreen", screenId);
              return;
            }
            template = await this.$store.dispatch("dashboard/fetchTemplate", {
              screenId: screenId,
              updateStatus:
                parseInt(this.$store.getters["dashboard/dashboardScreenId"]) ==
                parseInt(screenId)
            });
          }
          if (template) {
            this.$emit("openScreen", {
              screenId,
              connectorId: connector_id,
              actionParams: actionParams
            });
          }
          break;
        case "tab":
          this.$refs.newtab_link.target = "_blank";
          this.$refs.newtab_link.href = url;
          this.$refs.newtab_link.click();
          break;
        case "window":
          {
            let params = {
              width: window.screen.width,
              height: window.screen.height,
              top: 0,
              left: 0,
              ...actionParams
            };
            let sParams = "";
            for (let param in params) {
              sParams +=
                (sParams ? "," : "") +
                param +
                "=" +
                encodeURIComponent(params[param]);
            }
            window.open(url, document.title, sParams);
          }
          break;
        case "modal":
          {
            let title = actionParams?.title || "";
            this.dialog.title = title;
            this.dialog.src = url + "?content_only=true";
            this.dialog.ready = false;
            $(this.$refs.iframe_dialog).modal("show");
          }
          break;
        case "popup":
          {
            let params = {
              title: "",
              width: 960,
              height: 660,
              top: 50,
              left: 50,
              ...actionParams
            };
            let src = url + "?content_only=true";
            let id = btoa(JSON.stringify(params));
            let popup = (this.popup_list || []).find((i) => i.id == id);
            if (popup) {
              if (popup.src && popup.src == src) {
                popup.src = "";
              } else {
                popup.src = src;
              }
            } else {
              this.popup_list.push({
                id: id,
                position: {
                  left: `${params.left}px`,
                  top: `${params.top}px`,
                  width: `${params.width}px`,
                  height: `${params.height}px`
                },
                title: params.title,
                dragging: false,
                ready: false,
                src: src
              });
            }
          }
          break;
      }
    },
    async trigger_print() {
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "native":
          window.print();
          break;
        case "preview":
          {
            let screenId =
              this.selectedAction?.action?.options?.screen_id?.value || "";
            if (!screenId) return;
            let url = `/dashboard/equipment/${this?.connector?.id ||
              "screen"}/${screenId}`;
            {
              let template = this.$store.getters["dashboard/template"](
                screenId
              );
              if (!template) {
                template = await this.$store.dispatch(
                  "dashboard/fetchTemplate",
                  screenId
                );
              }
              if (template) {
                if (
                  (url == this.$route.path &&
                    this?.$route?.query?.media != "print") ||
                  url != this.$route.path
                ) {
                  url += "?media=print";
                  if (this?.$route?.query?.content_only || "") {
                    url += "&content_only=true";
                  }
                  this.$router.push(url);
                }
              }
            }
          }
          break;
      }
    },
    evaluate(expression, data) {
      if (isNaN(parseInt(expression))) {
        let entry = {
          ...(data || {}),
          template: expression
        };
        let vlr = this.$root.$formatter.format(entry);
        return data.type == "string"
          ? vlr
          : parseInt(
            vlr !== "" && vlr !== "-" && vlr !== null && vlr !== undefined
              ? vlr
              : expression
          );
      }
      return expression;
    },
    trigger_script() { },
    trigger_user() {
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "logout":
          this.$store.dispatch("user/logout");
          this.$store.dispatch("reset");
          break;
        case "profile":
          this.$router.push("/dashboard/profile");
          break;
        case "manage":
          this.$router.push("/dashboard/users");
          break;
      }
    },
    trigger_tag() {
      const options = this?.selectedAction?.action?.options || {};
      const dataId = options?.data_id?.data_id || options?.data_id?.value || 0;
      const lastData = (this.$store.getters["dashboard/dataList"] || []).find(
        (i) => i.id == dataId
      );
      if (!lastData) {
        throw "invalid_data";
      }
      let dataIndex =
        lastData.memory_size > 1 && options?.data_index?.value !== ""
          ? options?.data_index?.value || 0
          : undefined;
      if (dataIndex !== undefined) {
        if (isNaN(parseInt(dataIndex))) {
          let vlr = this.evaluate(dataIndex, lastData);
          if (isNaN(vlr)) {
            this.toast(["titles.data_value_index"]);
            return;
          }
          dataIndex = vlr;
        }
        if (dataIndex < 0 || dataIndex > lastData.memory_size - 1) {
          this.toast(["titles.data_value_index", "out_of_range"]);
          return;
        }
      }
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "write_value": {
          let value = options?.constant?.value || "";
          if (value !== "") {
            value = isNaN(parseInt(value))
              ? this.evaluate(value, lastData)
              : value;
            this.saveData(lastData, { value: value, dataIndex: dataIndex });
          }
          break;
        }
        case "set_constant": {
          let value = options?.constant?.value || "";
          if (value !== "") {
            value = isNaN(parseInt(value))
              ? this.evaluate(value, lastData)
              : value;
            this.saveData(lastData, { value: value, dataIndex: dataIndex });
          }
          break;
        }
        case "invert":
        case "set_next": {
          let values = Object.values(options?.params || {});
          if (values.length) {
            let previous = Number(lastData?.current_value?.value);
            values = values
              .filter((v) => v !== "" && !isNaN(Number(v)))
              .map((v) => Number(v));
            let i = values.indexOf(previous) + 1;
            if (i >= values.length) {
              i = 0;
            }
            this.saveData(lastData, { value: values[i], dataIndex: dataIndex });
          }
          break;
        }
      }
    },
    trigger_form() {
      const options = this?.selectedAction?.action?.options || {};
      const formName = options?.formName?.value || "form1";
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "submit": {
          this.$root.$emit("form:submit", formName);
          break;
        }
        case "reset": {
          this.$root.$emit("form:reset", formName);
          break;
        }
      }
    },
    trigger_style() {
      let synopticComponent =
        this?.selectedAction?.source?.synopticComponent || null;
      if (!synopticComponent) return;
      let tmp = JSON.parse(JSON.stringify(synopticComponent?.tmp || null));
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "color":
          {
            let fgcolor =
              this.selectedAction?.action?.options?.color?.value || "";
            tmp = tmp || {};
            tmp.style = tmp.style || {};
            tmp.style["color"] = fgcolor;
          }
          break;
        case "background_color":
          {
            let bgcolor =
              this.selectedAction?.action?.options?.backgroundColor?.value ||
              "";
            tmp = tmp || {};
            tmp.style = tmp.style || {};
            tmp.style["background-color"] = bgcolor;
          }
          break;
        case "hide":
          {
            tmp = tmp || {};
            tmp.style = tmp.style || {};
            tmp.style["visibility"] = "hidden";
          }
          break;
        case "show":
          {
            tmp = tmp || {};
            tmp.style = tmp.style || {};
            tmp.style["visibility"] = "visible";
          }
          break;
        case "disable":
          {
            tmp = tmp || {};
            tmp.style = tmp.style || {};
            tmp.style["visibility"] = "visible";
            tmp.style["--hi-disable"] = 1;
          }
          break;
        case "css":
        case "animation":
          {
            tmp = tmp || {};
            // attention:
            // position properties are handled by the rect tmp prop
            // and not the style one
            let params = this.selectedAction?.action?.options?.params || {};
            for (var prop in params) {
              if (["top", "left", "right", "width"].indexOf(prop) >= 0) {
                let n = this.$utils.asNumber(params[prop]);
                if (n !== "") {
                  tmp.rect = tmp.rect || {};
                  tmp.rect[prop] = parseFloat(n);
                }
              } else {
                if (params[prop] == "class") {
                  tmp.classList = tmp.classList || {};
                  this.$utils
                    .trim(prop)
                    .split(" ")
                    .forEach((cls) => {
                      tmp.classList[cls] = true;
                    });
                } else {
                  tmp.style = tmp.style || {};
                  tmp.style[prop] = params[prop];
                }
              }
            }
          }
          break;
      }
      if (tmp) {
        this.$set(synopticComponent, "tmp", tmp);
      }
    },
    trigger_content() {
      let synopticComponent =
        this?.selectedAction?.source?.synopticComponent || null;
      if (!synopticComponent) return;
      let tmp = JSON.parse(JSON.stringify(synopticComponent?.tmp || null));
      switch (this.selectedAction.action.type.split(":")[1]) {
        case "text":
          // let text = this.selectedAction?.action?.options?.text?.value || "";
          // if ("value" in synopticComponent) {
          //   synopticComponent.value = text;
          // } else if ("text" in synopticComponent) {
          //   synopticComponent.text = text;
          // }
          tmp = tmp || {};
          tmp.text = this.selectedAction?.action?.options?.text?.value || "";
          break;
        case "image":
          // let src = this.selectedAction?.action?.options?.image?.value || "";
          // if ("src" in synopticComponent) {
          //   synopticComponent.src = src;
          // }
          tmp = tmp || {};
          tmp.src = this.selectedAction?.action?.options?.image?.value || "";
          break;
      }
      if (tmp) {
        this.$set(synopticComponent, "tmp", tmp);
      }
    },
    trigger(custom_action) {
      const type = custom_action.action.type || "";
      const group = type ? type.split(":")[0] : "";
      const fn_name = `trigger_${group}`;
      if (fn_name in this && typeof this[fn_name] == "function") {
        // private parameters
        let params = null;
        let ctrls = JSON.parse(
          JSON.stringify(custom_action?.action?.options?.params || {})
        );
        for (var p in ctrls) {
          params = params || {};
          params[p.replace(/^_/, "")] = ctrls[p];
        }
        if (params) {
          custom_action.action.options.params = params; //JSON.parse(JSON.string(params));
        }
        this.selectedAction = custom_action;
        this[fn_name]();
      }
      return;
    },
    onPopupDragged(popup, $event) {
      popup.position.top = $event.top;
      popup.position.left = $event.left;
      popup.dragging = false;
    },
    on_custom_action(custom_action) {
      if (custom_action?.action) {
        try {
          this.trigger(custom_action);
        } catch (e) {
          // todo
          //console.log(e);
        }
      }
    },
    setupDialog() {
      let self = this;
      $(self.$refs.iframe_dialog)
        .on("hide.bs.modal", function() {
          self.dialog.ready = false;
          self.dialog.src = "";
        })
        .on("shown.bs.modal", function() { });
    },
    toast(lst, type) {
      this.$toasted.show(lst.map((i) => this.$t(i)).join("<br/>"), {
        position: "bottom-right",
        duration: 5000,
        keepOnHover: true,
        type: type ?? "error",
        icon: (type ?? "error") == "error" ? "exclamation" : "check",
        iconPack: "fontawesome"
      });
    }
  },
  mounted() {
    this.setupDialog();
  },
  created() {
    this.$root.$on("custom_action", this.on_custom_action);
    window.onmessage = (e) => {
      if (e.data && typeof e.data == "object" && "hi_msg" in (e.data || {})) {
        if (e.data.hi_msg == "NACK") {
          if (e.data.from) {
            this.notifyData(document.getElementById(e.data.from));
          } else {
            this.notifyData();
          }
        }
      }
    };
  },
  beforeDestroy() {
    this.$root.$off("custom_action", this.on_custom_action);
  }
};
</script>

<style scoped>
.box-tools {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

.btn-img {
  width: 20px;
  height: 24px;
  background-size: contain;
  opacity: 0.5;
  background-repeat: no-repeat;
  margin-top: 2px;
}

.btn-img:hover {
  opacity: 0.8;
}

.scrollable-iframe {
  border: 0;
  min-height: 85vh;
  overflow: auto;
  width: 100%;
}
.modal-lg {
  width: 70%;
  min-width: 1440px;
}

.modal-content {
  border-radius: 4px;
}

.float-panel {
  padding: 20px 0 0 0;
  overflow: hidden;
  background: #ffffffa8;
}
.popup-title {
  position: absolute;
  top: -15px;
  left: 5px;
}
</style>
