// import Vue from "vue";
import Utils from "@/plugins/utils.js";
import { debounce } from "lodash";

const src = `${document.location.origin}/bower_components/mqtt/mqttws31.js`;

export default (() => {
  class MQTT {
    constructor(options) {
      let self = this;
      this._status = "";
      this._details = "";
      this._client = null;
      this._config = null;
      this._message = null;

      this.onStatusChanged = options?.onStatusChanged || null;

      this.onMessage = options?.onMessage || null;

      this.setStatus("IDLE");
      // inject
      if (window.Paho) {
        setTimeout(() => {
          self.setStatus("READY"); // must but in another thread once it is running on constructor
        }, 0);
      } else {
        Utils.injectScript(src)
          .then(() => {
            if (window.Paho) {
              self.setStatus("READY");
            }
          })
          .catch((e) => {
            self.setStatus("ERROR", e);
          });
      }
      return this;
    }

    setStatus(status, details) {
      this._status = status;
      this._details = details || "";
      if (this.onStatusChanged) {
        try {
          (async () => {
            this.onStatusChanged(this._status, this._details);
          })();
        } catch (e) { }
      }
      return this;
    }

    onConnected() {
      this.setStatus("CONNECTED");
      (this._config.topics || []).forEach((topic) => {
        this._client.subscribe(topic);
      });
    }

    onFailure(e) {
      if (this._config && this._config.reconnectTimeout && this._client) {
        this.setStatus("ERROR", e);
        setTimeout(
          () => {
            this.connect();
          },
          this._config.reconnectTimeout,
          this
        );
      }
    }

    onDisconnected(e) {
      let self = this;
      this.setStatus("DISCONNECTED", e);
      if (this._client) {
        setTimeout(() => {
          if (this._client) {
            self.connect();
          }
        }, this._config.reconnectTimeout);
      }
    }

    connect() {
      let self = this;
      let options = null;
      let map = {
        userName: "userName",
        password: "password",
        encrypted: "useSSL",
        keepAliveInterval: "keepAliveInterval"
      };
      for (let k in map) {
        if (k in this._config) {
          options = options || {};
          options[map[k]] = this._config[k];
        }
      }
      this.setStatus("CONNECTING");
      if (this._client) {
        if (!this._client.onMessageArrived) {
          this._client.onMessageArrived = (data) => {
            self.onMessageArrived(data);
          };
        }
        if (!this._client.onConnectionLost) {
          this._client.onConnectionLost = (e) => {
            self.onDisconnected(e);
          };
        }
        let cfg = {
          onSuccess: (data) => {
            self.onConnected(data);
          },
          onFailure: (e) => {
            self.onFailure(e);
          },
          ...(options || {})
        };
        this._client.connect(cfg);
      }
    }

    onMessageArrived(info) {
      try {
        if (this.onMessage) {
          const smsg = info ? info?.payloadString : "";
          // console.log(smsg);
          if (smsg) {
            let msg = JSON.parse(smsg);
            this._message = (typeof msg === 'string' && msg == 'null') ? null : msg;
            //self.onMessage(this._message, info);
            (async () => {
              this.onMessage(this._message, info);
            })();
          }
        }
      } catch (e) {
        // console.log(e)
      }
    }

    setup(cfg) {
      this._config = {
        host: (cfg || {})?.websocket?.host || "",
        port: (cfg || {})?.websocket?.port || "",
        encrypted: (cfg || {})?.websocket?.encrypted || false,
        reconnectTimeout: (cfg || {})?.websocket?.reconnectTimeout || 2000,
        clientId: (cfg || {})?.clientId || Utils.uuid(),
        topics: (cfg || {})?.topics || [],
        userName: (cfg || {})?.userName || "",
        password: (cfg || {})?.password || ""
      };
      if (window.Paho) {
        this._client = new window.Paho.MQTT.Client(
          this._config.host,
          Number(this._config.port),
          this._config.clientId
        );
        this.connect();
      }
    }

    publish(msg) {
      try {
        let message = new window.Paho.MQTT.Message(JSON.stringify(msg));
        message.destinationName = this._config.topics[0];
        debounce(function() {
          this._client.send(message);
        }, 100);
      } catch (e) {
        console.log(e);
      }
    }

    disconnect() {
      this._client.disconnect();
    }

    destroy() {
      if (this._client && this._client.isConnected()) {
        this._client.disconnect();
      }
      this._status = "";
      this._details = "";
      this._client = null;
      this._config = null;
      this._message = null;
      if (this.onStatusChanged) {
        this.onStatusChanged = () => { }
      }
      if (MQTT.instance) {
        delete MQTT.instance;
        MQTT.instance = null;
      }
    }
  }

  return {
    // Singleton factory
    MQTT(options) {
      if (!MQTT.instance) {
        MQTT.instance = new MQTT(options);
      }
      return MQTT.instance;
    }
  };
})();
